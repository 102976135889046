<template>
  <div>
    <div v-if="isLoading">
      Cargando reportes...
    </div>
    <div v-else>
      <CRow class="mb-3">
        <CCol class="d-flex justify-content-end">
          <CButtonGroup role="group">
            <CButton v-on:click="changeReport('daily')" color="primary" :variant="this.currentReport == 'daily' ? '': 'outline'">Diario</CButton>
            <CButton v-on:click="changeReport('weekly')" color="primary" :variant="this.currentReport == 'weekly' ? '': 'outline'">Semanal</CButton>
            <CButton v-on:click="changeReport('mothly')" color="primary" :variant="this.currentReport == 'mothly' ? '': 'outline'">Mensual</CButton>
            <CButton v-on:click="changeReport('historic')" color="primary" :variant="this.currentReport == 'historic' ? '': 'outline'">Historico</CButton>
          </CButtonGroup>
        </CCol>
      </CRow>
      <WidgetsDropdown :report="this.currentReport" />
      <CCardGroup columns class="card-columns cols-2">
        <CCard>
          <CCardHeader>
            Leads por marca 
            <div class="float-right d-inline">
              <CBadge color="dark">{{this.leadsTotal}}</CBadge>
            </div>
          </CCardHeader>
          <CCardBody>
            <CChartPie
              :datasets="leadsPorMarcaDataset"
              :labels="leadsPorMarcaLabels"
            />
          </CCardBody>
        </CCard>
        
        <CCard>
          <CCardHeader>
            Porcentaje de respuestas automatizadas
            <div class="float-right d-inline">
              <CBadge color="dark">{{this.leadsTotal}}</CBadge>
            </div>
          </CCardHeader>
          <CCardBody>
            <CChartDoughnut
              :datasets="leadsAutomatizadosDataset"
              :labels="leadsAutomatizadosLabels"
            />
          </CCardBody>
        </CCard>

        <CCard>
          <CCardHeader>
            Leads por tipo de respuesta 
            <div class="float-right d-inline">
              <CBadge color="dark">{{this.leadsTotal}}</CBadge>
            </div>
          </CCardHeader>
          <CCardBody>
            <CChartPie
              :datasets="leadsPorTipoDataset"
              :labels="leadsPorTipoLabels"
            />
          </CCardBody>
        </CCard>
      </CCardGroup>
    </div>
  </div>
</template>

<script>
import { CChartPie, CChartDoughnut } from '@coreui/vue-chartjs';
import WidgetsDropdown from './widgets/WidgetsDropdown';
const reportService = require('../services/report.service');
export default {
  name: 'Dashboard',
  components: {
    CChartPie,
    CChartDoughnut,
    WidgetsDropdown
  },
  data () {
    return {
      isLoading: true,
      currentReport: 'daily',
      reporte: {},

      leadsTotal: 0,

      leadsPorMarcaDataset: [],
      leadsPorMarcaLabels: [],

      leadsPorTipoDataset: [],
      leadsPorTipoLabels: [],

      leadsAutomatizadosDataset: [],
      leadsAutomatizadosLabels: [],
    }
  },
  mounted() {
    this.getHistoricReport();
  },
  methods: {
    changeReport: function(period){

      switch (period) {
        case 'daily':
          
          this.getDailyReport();
          this.currentReport = period;

          break;
        case 'weekly':
          
          this.getWeeklyReport();
          this.currentReport = period;

          break;
        case 'mothly':

          this.getMonthlyReport();
          this.currentReport = period;

          break;
        case 'historic':

          this.getHistoricReport();
          this.currentReport = period;

          break;
        default:

          this.getHistoricReport();
          this.currentReport = period;
          
          break;
      }


    },
    getHistoricReport: async function(){
      reportService.reporteHistorico(this.$store.state.user.data.id).then((response) => {
        // handle success
        this.reporte = response.data.report;

        //Total de leads historico
        this.leadsTotal = this.reporte.total;

        //Leads por marca
        this.leadsPorMarcaDataset = this.leadsPorMarcaDatasetSetup();
        this.leadsPorMarcaLabels = this.leadsPorMarcaLabelsSetup();

        //Leads por tipo
        this.leadsPorTipoDataset = this.leadsPorTipoDatasetSetup();
        this.leadsPorTipoLabels = this.leadsPorTipoLabelsSetup();

        //Leads automatizados
        this.leadsAutomatizadosDataset = this.leadsAutomatizadosDatasetSetup();
        this.leadsAutomatizadosLabels = this.leadsAutomatizadosLabelsSetup();

        this.isLoading = false;
      })
      .catch((error) => {
        // handle error
        console.log(error);
      })
    },

    getMonthlyReport: async function(){
      reportService.reporteMensual(this.$store.state.user.data.id).then((response) => {
        // handle success
        this.reporte = response.data.report;

        //Total de leads historico
        this.leadsTotal = this.reporte.total;

        //Leads por marca
        this.leadsPorMarcaDataset = this.leadsPorMarcaDatasetSetup();
        this.leadsPorMarcaLabels = this.leadsPorMarcaLabelsSetup();

        //Leads por tipo
        this.leadsPorTipoDataset = this.leadsPorTipoDatasetSetup();
        this.leadsPorTipoLabels = this.leadsPorTipoLabelsSetup();

        //Leads automatizados
        this.leadsAutomatizadosDataset = this.leadsAutomatizadosDatasetSetup();
        this.leadsAutomatizadosLabels = this.leadsAutomatizadosLabelsSetup();

        this.isLoading = false;
      })
      .catch((error) => {
        // handle error
        console.log(error);
      })
    },
    getWeeklyReport: async function(){
      reportService.reporteSemanal(this.$store.state.user.data.id).then((response) => {
        // handle success
        this.reporte = response.data.report;

        //Total de leads historico
        this.leadsTotal = this.reporte.total;

        //Leads por marca
        this.leadsPorMarcaDataset = this.leadsPorMarcaDatasetSetup();
        this.leadsPorMarcaLabels = this.leadsPorMarcaLabelsSetup();

        //Leads por tipo
        this.leadsPorTipoDataset = this.leadsPorTipoDatasetSetup();
        this.leadsPorTipoLabels = this.leadsPorTipoLabelsSetup();

        //Leads automatizados
        this.leadsAutomatizadosDataset = this.leadsAutomatizadosDatasetSetup();
        this.leadsAutomatizadosLabels = this.leadsAutomatizadosLabelsSetup();

        this.isLoading = false;
      })
      .catch((error) => {
        // handle error
        console.log(error);
      })
    },
    getDailyReport: async function(){
      reportService.reporteDiario(this.$store.state.user.data.id).then((response) => {
        // handle success
        this.reporte = response.data.report;

        //Total de leads historico
        this.leadsTotal = this.reporte.total;

        //Leads por marca
        this.leadsPorMarcaDataset = this.leadsPorMarcaDatasetSetup();
        this.leadsPorMarcaLabels = this.leadsPorMarcaLabelsSetup();

        //Leads por tipo
        this.leadsPorTipoDataset = this.leadsPorTipoDatasetSetup();
        this.leadsPorTipoLabels = this.leadsPorTipoLabelsSetup();

        //Leads automatizados
        this.leadsAutomatizadosDataset = this.leadsAutomatizadosDatasetSetup();
        this.leadsAutomatizadosLabels = this.leadsAutomatizadosLabelsSetup();

        this.isLoading = false;
      })
      .catch((error) => {
        // handle error
        console.log(error);
      })
    },
    leadsPorMarcaDatasetSetup: function(){
      let marcas = [];
      let counts = [];
      this.reporte.por_marca.forEach(function(marca){
        marcas.push(marca.marca);
        counts.push(marca.count);
      });

      return [{
        backgroundColor: [
            '#41B883',
            '#E46651',
            '#00D8FF',
            '#DD1B16'
          ],
        data: counts
      }];
    },
    leadsPorMarcaLabelsSetup: function(){
      let marcas = [];
      this.reporte.por_marca.forEach(function(marca){
        marcas.push(marca.marca);
      });

      return marcas;
    },
    leadsPorTipoDatasetSetup: function(){
      let tipos = [];
      let counts = [];
      this.reporte.por_tipo_respuesta.forEach(function(tipo_respuesta){
        tipos.push(tipo_respuesta.tipo);
        counts.push(tipo_respuesta.count);
      });

      return [{
        backgroundColor: [
            '#41B883',
            '#E46651',
            '#00D8FF',
            '#DD1B16'
          ],
        data: counts
      }];
    },
    leadsPorTipoLabelsSetup: function(){
      let tipos = [];
      this.reporte.por_tipo_respuesta.forEach(function(tipo_respuesta){
        tipos.push(tipo_respuesta.tipo);
      });

      return tipos;
    },

    leadsAutomatizadosDatasetSetup: function(){
      let automatizados = 0;
      let noAutomatizados = 0;
      this.reporte.por_tipo_respuesta.forEach(function(tipo_respuesta){
        if(tipo_respuesta.tipo == 'Caso en analisis'){
          noAutomatizados += tipo_respuesta.count;
        }else{
          automatizados += tipo_respuesta.count;
        }
      });

      let counts = [automatizados, noAutomatizados];

      return [{
        backgroundColor: [
            '#41B883',
            '#E46651',
          ],
        data: counts
      }];
    },
    leadsAutomatizadosLabelsSetup: function(){
      let labels = ['Automatizados', 'Sin automatizar'];

      return labels;
    },
  }
}
</script>
