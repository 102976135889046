const axios = require('axios');


const reportService = {

    reporteDiario(usuario_id) {
        return axios.get(`${process.env.VUE_APP_API_URL}api/reportes/diario/${usuario_id}`,{
            headers: {
            'x-token-clica': 'agenciaclica123'
            }
        });
    },

    reporteSemanal(usuario_id) {
        return axios.get(`${process.env.VUE_APP_API_URL}api/reportes/semanal/${usuario_id}`,{
            headers: {
            'x-token-clica': 'agenciaclica123'
            }
        });
    },

    reporteMensual(usuario_id) {
        return axios.get(`${process.env.VUE_APP_API_URL}api/reportes/mensual/${usuario_id}`,{
            headers: {
            'x-token-clica': 'agenciaclica123'
            }
        });
    },

    reporteHistorico(usuario_id) {
        return axios.get(`${process.env.VUE_APP_API_URL}api/reportes/historico/${usuario_id}`,{
            headers: {
            'x-token-clica': 'agenciaclica123'
            }
        });
    },
}

module.exports = reportService;