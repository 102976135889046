<template>
  <CRow>
    <CCol sm="6" lg="3">
      <CWidgetDropdown class="pb-4" color="primary" :header="this.reporte.report.total.toString()" text="Leads totales">
      </CWidgetDropdown>
    </CCol>
    <CCol sm="6" lg="3">
      <CWidgetDropdown class="pb-4" color="info" :header="this.presupuestos_enviados.count.toString()" text="Presupuestos enviados">
      </CWidgetDropdown>
    </CCol>
    <CCol sm="6" lg="3">
      <CWidgetDropdown
        class="pb-4"
        color="warning"
        :header="typeof this.reporte.report.ventas_confirmadas === 'object' ? (this.reporte.report.ventas_confirmadas.total).toLocaleString('es-ES', {  style: 'currency',  currency: 'ARS',}) : (this.reporte.report.ventas_confirmadas).toLocaleString('es-ES', {  style: 'currency',  currency: 'ARS',})"
        text="En reparaciones vendidas"
      >
      </CWidgetDropdown>
    </CCol>
    <CCol sm="6" lg="3">
      <CWidgetDropdown
        class="pb-4"
        color="danger"
        :header="typeof this.reporte.report.ventas_potenciales === 'object' ? (this.reporte.report.ventas_potenciales.total).toLocaleString('es-ES', {  style: 'currency',  currency: 'ARS',}) : (this.reporte.report.ventas_potenciales).toLocaleString('es-ES', {  style: 'currency',  currency: 'ARS',})"
        text="En ventas potenciales"
      >
      </CWidgetDropdown>
    </CCol>
  </CRow>
</template>

<script>
const reportService = require('../../services/report.service');

export default {
  name: 'WidgetsDropdown',
  props: ['report'],
  watch: { 
    report: function(newVal, oldVal) { // watch it
      console.log('Prop changed: ', newVal, ' | was: ', oldVal)
      this.changeReport(this.report);
    }
  },
  data() {
    return {
      reporte: {},
      presupuestos_enviados: {},
      isLoading: true,
    }
  },
  mounted() {
    this.changeReport(this.report);
  },
  methods: {
    changeReport: function(period){

      switch (period) {
        case 'daily':
          
          this.getDaily();

          break;
        case 'weekly':
          
          this.getWeekly();

          console.log(this.reporte);

          break;
        case 'mothly':

          this.getMonthly();

          break;
        case 'historic':

          this.getHistoric();

          break;
        default:

          this.getHistoric();
          
          break;
      }


    },
    getDaily: async function(){
      reportService.reporteDiario(this.$store.state.user.data.id).then((response) => {
        // handle success
        this.reporte = response.data;

        this.calcularTotalPresupuestos();
      })
      .catch((error) => {
        // handle error
        console.log(error);
      })
    },

    getWeekly: async function(){
      reportService.reporteSemanal(this.$store.state.user.data.id).then((response) => {
        // handle success
        this.reporte = response.data;

        this.calcularTotalPresupuestos();
      })
      .catch((error) => {
        // handle error
        console.log(error);
      })
    },

    getMonthly: async function(){
      reportService.reporteMensual(this.$store.state.user.data.id).then((response) => {
        // handle success
        this.reporte = response.data;

        this.calcularTotalPresupuestos();
      })
      .catch((error) => {
        // handle error
        console.log(error);
      })
    },

    getHistoric: async function(){
      reportService.reporteHistorico(this.$store.state.user.data.id).then((response) => {
        // handle success
        this.reporte = response.data;

        console.log(this.reporte);

        this.calcularTotalPresupuestos();
      })
      .catch((error) => {
        // handle error
        console.log(error);
      })
    },

    calcularTotalPresupuestos: function(){
      let tipos_respuesta = this.reporte.report.por_tipo_respuesta;
      tipos_respuesta.forEach(resp => {
        if(resp.tipo == 'Presupuesto'){
          this.presupuestos_enviados = resp;
        }
      });
    },
  }
}
</script>
